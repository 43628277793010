import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

function AccessibilityStatement() {
  return (
    <Layout>
      <SEO
        title="Accessibility Statement"
        description="D2i Technology website conforms to WCAG 2.1, Level AA issued by the Web Accessibility Initiative of the W3C and is Section 508 compliant."
      />
      <div className="text-justify">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h1>Accessibility Statement</h1>
              </div>
              <div className="content-section">
                <p>Access for All: Our Commitment towards Accessibility</p>
                <p>
                  <i>Last Updated: November 2024</i>
                </p>
                <p>
                  <strong>D2i Technology</strong> is committed to ensuring
                  digital accessibility for all, including people with
                  disabilities. To meet this commitment, the{" "}
                  <a href="/">
                    d2itechnology.com
                  </a>{" "}
                  website is designed to be accessible and has been tested by
                  people with disabilities using various assistive technologies.
                </p>
                <h2 className="cloudhead h3">Standards Compliance</h2>
                <p className="mb-2">
                  Our site conforms to: - Web Content Accessibility Guidelines
                  (WCAG) 2.1, Level AA issued by the{" "}
                  <a
                    href="https://www.w3.org/WAI/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Web Accessibility Initiative (WAI)
                  </a>{" "}
                  of the{" "}
                  <a href="https://www.w3.org/" target="_blank" rel="noopener noreferrer">
                    World Wide Web Consortium (W3C)
                  </a>{" "}
                  -{" "}
                  <a
                    href="https://www.section508.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Section 508
                  </a> {" "}
                  requirements.
                </p>
                <h2 className="cloudhead h3">Ongoing Commitment</h2>
                <p className="mb-2">
                  The D2i Technology website is tested and monitored regularly
                  to incorporate features that enhance accessibility and
                  usability for people with disabilities.
                </p>
                <h2 className="cloudhead h3">Contact Us</h2>
                <p className="mb-2">
                  Please{" "}
                  <a href="mailto:info@d2itechnology.com">
                    contact us
                  </a>{" "}
                  if you encounter an accessibility issue on this site. Your
                  feedback is extremely important to us.
                </p>
                <hr className="border-secondary" aria-hidden="true" />
                <p>
                  <i>
                    Together, let’s make the digital world accessible to
                    everyone.
                  </i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AccessibilityStatement
